import { querySelectorDeep } from './querySelectorDeep.js';
import { querySelectorAllDeep } from './querySelectorAllDeep.js';

function validateEmail(email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
}
function validatePassword(password) {
    return password.length >= 8;
}
function validatePhoneNumber(phoneNumber) {
    // Удаляем пробелы, дефисы и скобки
    const cleanedPhoneNumber = phoneNumber.replace(/[\s\-()]/g, '');

    // Проверяем номер по маске
    const regex = /^\+\d{11,15}$/;

    return regex.test(cleanedPhoneNumber);
}

export function validateForm(formID) {
    formID = querySelectorDeep(document.body, `#${formID}`);

    const fields = querySelectorAllDeep('component-input-text, component-cell, component-input-image, component-textarea, component-input-checkbox, component-select, component-switch', formID);

    let hasError = false;

    fields.forEach(field => {
        if (field.id) {
            let props = field.getAttribute("props");
            if (props) {
                props = JSON.parse(decodeURIComponent(atob(props)));
            }
            //let value = props?.value ? props.value : null;
            let value = props?.value ?? null;
            let required = props?.required ? props.required : false;

            // Проверка на обязательность поля
            if (!value && required) {
                field.setAttribute('error', 'Поле не заполнено');
                hasError = true;
            }
            
            // Валидация email
            if (value && field.id === 'email') {
                if (!validateEmail(value)) {
                    field.setAttribute('error', 'Неверный формат email');
                    hasError = true;
                }
            }

            // Валидация password
            if (value && field.id === 'newPassword') {
                if (!validatePassword(value)) {
                    field.setAttribute('error', 'Пароль должен быть не менее 8 символов');
                    hasError = true;
                }
            }

            // Валидация номера телефона
            if (value && field.id === 'phone') {
                if (!validatePhoneNumber(value)) {
                    field.setAttribute('error', 'Неверный формат номера телефона');
                    hasError = true;
                }
            }

        }
    });

    if (hasError) {
        return false;
    } else {
        return true;
    }
}
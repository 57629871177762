import { getIcon } from './getIcon.js';
import { getData } from './getData.js';
import { createComponent } from './createComponent.js';
import { postData } from './postData.js';
import { querySelectorDeep } from './querySelectorDeep.js';
import { querySelectorAllDeep } from './querySelectorAllDeep.js';
import { collectFormData } from './collectFormData.js';
import { getAccount } from './getAccount.js';

import { html, nothing } from 'lit';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { until } from 'lit/directives/until.js';

import { config } from '../config.js';
import { design } from '../app.js';

let pixelRatio = window.devicePixelRatio || 1;

/* const memoizedEvalCode = (function() {
    const cache = new Map();

    return function(value, data = {}, slug = '', props = {}) {
        const key = JSON.stringify([value, data, slug, props]);
        if (cache.has(key)) {
            return cache.get(key);
        }
        try {
            const func = new Function('data', 'slug', 'props', `return ${value}`);
            const result = func(data, slug, props);
            if (!value.includes('window.') && !value.includes('localStorage.') && !value.includes('sessionStorage.') && !value.includes('new Date')) {
                cache.set(key, result);
            }
            return result;
        } catch (error) {
            return value;
        }
    };
})(); */

const memoizedEvalCode = (function () {
    const cache = new Map();

    return function (value, data = {}, slug = '', props = {}) {
        const key = JSON.stringify([value, data, slug, props]);

        if (cache.has(key)) {
            return cache.get(key);
        }

        try {
            const parsedValue = isNaN(value) ? value : `'${value}'`;
            const func = new Function('data', 'slug', 'props', `return ${parsedValue}`);
            const result = func(data, slug, props);

            if (!value.includes('window.') && !value.includes('localStorage.') && !value.includes('sessionStorage.') && !value.includes('new Date')) {
                cache.set(key, result);
            }

            return result;
        } catch (error) {
            return value;
        }
    };
})();

const memoizedUserEvalCode = (function () {
    const cache = new Map();

    return function (value, data = {}, slug = '', props = {}) {
        const key = JSON.stringify([value, data, slug, props]);
        if (cache.has(key)) {
            return cache.get(key);
        }
        try {
            // Базовая проверка на опасные конструкции
            const prohibitedPatterns = ['window', 'localStorage.setItem', 'localStorage.removeItem', 'sessionStorage.setItem', 'sessionStorage.removeItem', 'alert', 'document', 'eval', 'Function'];

            // Проверка текста на запрещенные выражения
            if (prohibitedPatterns.some(pattern => value.includes(pattern))) {
                return value;
            }

            // Создаем функцию для безопасного выполнения
            const func = new Function('data', 'slug', 'props', `return ${value}`);

            const result = func(data, slug, props);
            cache.set(key, result);
            return result;
        } catch (error) {
            //console.error('Evaluation error:', error);
            return value; // Или верните какое-то безопасное значение, либо null
        }
    };
})();

/* export function executeDynamicValues(obj, data = {}, slug = '', props = {}) {
    for (const [key, value] of Object.entries(obj)) {
        if (typeof value === 'string') {
            obj[key] = key === 'onclick' || key === 'setProps' || key === 'js' || key === 'query' ? value : memoizedEvalCode(value, data, slug, props);
        } else if (typeof value === 'object' && value !== null) {
            // Проверяем, что ключ не 'query', чтобы предотвратить обработку объектов с этим ключом
            if (key === 'query' || key === 'setProps') {
                obj[key] = value; // возвращаем как есть, без дальнейшей обработки
                //executeDynamicValues(value, data, slug, props);
            } else {
                executeDynamicValues(value, data, slug, props);
            }
        }
    }
    return obj;
} */

export function executeDynamicValues(obj, data = {}, slug = '', props = {}) {
    for (const [key, value] of Object.entries(obj)) {
        if (typeof value === 'string') {
            obj[key] = key === 'onclick' || key === 'setProps' || key === 'js' ? value : memoizedEvalCode(value, data, slug, props);
        } else if (typeof value === 'object' && value !== null) {
            if (key === 'query') {
                if (Array.isArray(value)) {
                    for (let item of value) {
                        if (item.column) {
                            item.column = memoizedEvalCode(item.column, data, slug, props);
                        }
                        if (item.value) {
                            item.value = memoizedEvalCode(item.value, data, slug, props);
                        }
                    }
                }
            } else if (key === 'setProps') {
                obj[key] = value; // возвращаем как есть, без дальнейшей обработки
            } else {
                executeDynamicValues(value, data, slug, props);
            }
        }
    }
    return obj;
}

export function parseIfString(value) {
    try {
        return typeof value === 'string' ? JSON.parse(value) : value;
    } catch (error) {
        return value; // Возврат исходного значения, если это не JSON-строка
    }
}

function dynamicComponent(componentName, dataID, componentState, data, treeOverrides, props, link, transition, scroll_to, onclick, override, rootProps, positionSticky) {
    const tag = `component-${componentName}`;
    let className = props?.name ? props.name : null;
    let path = window.location.pathname;
    let pathName = path.split('/');
    let slug = '';
    if (pathName[2] && pathName[2] !== 'view' && pathName[2] !== 'edit' && pathName[2] !== 'add') {
        slug = pathName[2];
    };
    if (props) {
        props = executeDynamicValues(props, data, slug, rootProps);
        if (props?.query) {
            props.query = memoizedEvalCode(props.query, '', '', rootProps)
        }
    }
    //console.log(props);
    /* if (props) {
        props = Object.entries(props).reduce((acc, [key, value]) => {
            acc[key] = key === 'onclick' ? value : memoizedEvalCode(value, data, slug, rootProps); // Предполагается, что функция evalCode синхронная
            return acc;
        }, {});
        //console.log(componentProps);
    } */
    let inlineStyle = '';
    if (override?.hidden && override.hidden === true || props?.inlineStyle) {
        inlineStyle = override?.hidden && override.hidden === true ? 'display: none;' : props?.inlineStyle ? props.inlineStyle : '';
    }

    /* if (props?.query) {
        let propsModified = props;
        if (props.query?.query) {
            for (let item of props.query.query) {
                if (item.value) {
                    item.value = memoizedEvalCode(item.value, data, slug, rootProps);
                }
            }
        }
        until(
            getData(propsModified.query, rerender, renderState).then(response => {
                data = response;
            }),
            ``
        )
    } */

    return `
      <${tag}
        ${props?.id ? `id="${props.id}"` : dataID ? `id="${dataID}"` : ''}
        class="${className ? className : `component-${componentName}`}${positionSticky ? ' sticky' : ''}"
        ${componentState.startsWith('selected') ? 'selected' : ''}
        ${componentState.startsWith('error') ? 'error' : ''}
        ${data ? `data="${btoa(encodeURIComponent(JSON.stringify(data)))}"` : ''}
        overrides="${btoa(encodeURIComponent(JSON.stringify(treeOverrides || {})))}"
        ${link ? ` href="${link}"` : ''}
        ${transition ? ` transition="${transition}"` : ''}
        ${scroll_to ? ` scrollto="${scroll_to}"` : ''}
        ${onclick ? ` clickevent="${onclick}"` : ''}
        ${props ? ` props="${btoa(encodeURIComponent(JSON.stringify(props)))}"` : ''}
        ${props?.setProps ? ` setprops="${btoa(encodeURIComponent(JSON.stringify(props.setProps)))}"` : ''}
        ${inlineStyle ? ` style="${inlineStyle}"` : ''}
        ${props && props?.disabled && props.disabled === 'true' ? ' disabled' : ''}
      ></${tag}>
    `;
}

export function createHTML(tree, data, overrides, dataID, rootProps, rerender, renderState) {
    let props;
    if (tree.name) {
        try {
            props = JSON.parse(tree.name.replace(/'/g, "\""));
        } catch (e) {
            props = false;
        }
    }
    if (data) {
        data = JSON.parse(data);
    }
    if (props?.data) {
        data = props.data;
    }
    let className = props ? props.name : tree?.name ? tree.name.replace(/ /g, "_") : tree.id;
    let path = window.location.pathname;
    let pathName = path.split('/');
    let slug = '';
    if (pathName[2] && pathName[2] !== 'view' && pathName[2] !== 'edit' && pathName[2] !== 'add') {
        slug = pathName[2];
    };
    const attr = (value) => value ? value : nothing;
    function findOverrides(treeId) {
        let item = overrides.find(override => override?.target && override.target.some(id => id === treeId));
        if (item) {
            return {
                componentId: item.componentId,
                hidden: item.hidden,
                text: item.text
            };
        } else {
            return null;
        }
    }
    let override = '';
    let link = '';
    let transition = '';
    let scroll_to = '';

    if (props?.link) {
        props.link = memoizedEvalCode(props.link, data, slug, rootProps);
        link = props.link.replace('slug', data?.$id ? data.$id : data?.id ? data.id : '');
        if (props?.animation) {
            transition = props.animation;
        } else {
            transition = 'default';
        }
    }
    if (tree?.link) {
        let linkID = '';
        if (data) {
            linkID = data?.$id ? data.$id : data?.id ? data.id : '';
        }
        if (tree?.animation) {
            switch (tree.animation) {
                case -1:
                    transition = 'default';
                    break;
                case 1:
                    transition = 'slideout';
                    linkID = '';
                    break;
                case 2:
                    transition = 'slideup';
                    break;
                case 3:
                    transition = 'slidedown';
                    linkID = '';
                    break;
            }
        } else {
            transition = 'slidein';
        }
        if (tree?.keepScroll) {
            transition = 'keepScroll';
        }
        if (props && props?.scrollto) {
            scroll_to = props.scrollto;
        }
        if (tree.link === 'back') {
            link = 'back';
        } else {
            design.forEach(item => {
                if (item._t === "FRAME" && tree.link === item.id) {
                    let itemName = item.name.split('/');
                    link = `/${itemName[1]}${linkID && (transition !== 'slideout' || transition !== 'slidedown') ? `/${linkID}` : ''}/${itemName[2]}`;
                }
            });
        }
    }
    let conditionIf = true;
    let conditionElse = false;
    if (props && props?.if) {
        conditionIf = memoizedEvalCode(props.if, data, slug, rootProps);
    }
    let elseStyle = '';
    if (props && props?.else) {
        elseStyle = props.else.map(item => item.style);
        conditionElse = true;
    }
    if (overrides) {
        override = findOverrides(tree.id);
    }
    if (override?.hidden && override.hidden === true) {
        conditionIf = false;
    }
    if (props && props?.js) {
        html`${memoizedEvalCode(props.js, data, slug, rootProps)}`;
    }
    let onclick = '';
    if (props && props?.onclick) {
        onclick = props.onclick;
    }
    let setProps = '';
    if (props?.setProps) {
        /* setProps = props.setProps.reduce((acc, setProperty) => {
            Object.entries(setProperty).forEach(([key, path]) => {
                const value = memoizedEvalCode(path, data, slug, rootProps);
                acc[key] = value;
            });
            return acc;
        }, {}); */
        //setProps = executeDynamicValues(props.setProps, data, slug, rootProps);
        setProps = props.setProps;
    }

    if (props && props?.id) {
        props.id = memoizedEvalCode(props.id, data, slug, rootProps);
    }

    let mapping = '';
    if (props && props?.map) {
        mapping = memoizedEvalCode(props.map, data, slug, rootProps);
        if (typeof mapping === 'string') {
            try {
                mapping = JSON.parse(mapping);
            } catch (e) { }
        }
    }

    let positionSticky = false;
    if (props?.style && props.style.includes('position: sticky;')) {
        positionSticky = true;
    }

    if (props?.query) {
        if (props.query?.query) {
            for (let item of props.query.query) {
                if (item.value) {
                    item.value = memoizedEvalCode(item.value, data, slug, rootProps);
                }
            }
        }
    }

    if (conditionIf || conditionElse) {
        switch (tree._t) {
            case 'INSTANCE':
                if (getIcon(tree.componentId)) {
                    return html`${getIcon(override?.componentId || tree.componentId)}`;
                } else {
                    let componentName = '';
                    let componentState = '';
                    const componentToCheck = override?.componentId || tree.componentId;
                    design.forEach(component => {
                        if (component._t === "COMPONENT" && componentToCheck === component.componentId) {
                            [componentName, componentState] = component.name.split('/');
                        }
                    });
                    if (componentName) {
                        if (!customElements.get(componentName)) {
                            createComponent(componentName);
                        }
                        let treeOverrides = [
                            ...(tree?.overrides || []),
                            ...(overrides ? overrides.filter(item => 'target' in item) : []),
                        ];
                        return html`${unsafeHTML(dynamicComponent(componentName, dataID, componentState, data, treeOverrides, props, link, transition, scroll_to, onclick, override, rootProps, positionSticky))}`;
                    }
                }
                break;
            case 'FRAME':
                if (props?.query) {
                    /* return html`
                        <div class="frame-${className}${positionSticky ? ' sticky' : ''}" 
                            id="${attr(props && props?.id ? props.id : '')}" 
                            style="${attr(props.inlineStyle || !conditionIf && elseStyle || override?.hidden && override.hidden ? `${props.inlineStyle || ''} ${!conditionIf && elseStyle ? elseStyle : ''} ${override?.hidden && override.hidden ? 'display: none;' : ''}` : '')}"
                        >
                            ${Array.isArray(tree.layers) ? 
                                until(
                                    getData(props.query, rerender, renderState).then(data => {
                                        if (data && data.length > 0) {
                                            return data.map(item =>
                                                tree.layers.map(layer => createHTML(layer, JSON.stringify(item), overrides, `${props.query.collection}_${item.$id}`, rootProps, rerender, renderState))
                                            );
                                        } else {
                                            return tree.layers.map(layer => createHTML(layer, '', overrides, 'empty_content', rootProps));
                                        }
                                    }),
                                    html``
                                )
                                : ''
                            }
                        </div>
                    `; */
                    props.query = memoizedEvalCode(props.query, '', '', rootProps);
                    return html`
                        ${until(
                        getData(props.query, rerender, renderState).then(data => {
                            if (props.query?.response && props.query.response?.if) {
                                conditionIf = memoizedEvalCode(props.query.response.if, data, slug, rootProps);
                            }
                            if (conditionIf) {
                                return html`
                                        <div class="frame-${className}${positionSticky ? ' sticky' : ''}"
                                            id="${attr(props && props?.id ? props.id : '')}" 
                                            style="${attr(props.inlineStyle || !conditionIf && elseStyle ? `${props.inlineStyle || ''} ${!conditionIf && elseStyle ? elseStyle : ''}` : '')}">
                                            ${(data && data.length > 0) ? data.map(item =>
                                    tree.layers.map(layer => createHTML(layer, JSON.stringify(item), overrides, `${props.query.collection}_${item.$id}`, rootProps, rerender, renderState))
                                ) : tree.layers.map(layer => createHTML(layer, '', overrides, 'empty_content', rootProps))}
                                        </div>
                                    `;
                            } else {
                                return html``;
                            }
                        }),
                        html``
                    )}
                    `;
                } else if (props?.getAccount) {
                    /* return html`
                        <div class="frame-${className}${positionSticky ? ' sticky' : ''}" 
                            id="${attr(props && props?.id ? props.id : '')}" 
                            style="${attr(props.inlineStyle || !conditionIf && elseStyle || override?.hidden && override.hidden ? `${props.inlineStyle || ''} ${!conditionIf && elseStyle ? elseStyle : ''} ${override?.hidden && override.hidden ? 'display: none;' : ''}` : '')}"
                        >
                            ${Array.isArray(tree.layers) ? 
                                until(
                                    getAccount(rerender, renderState).then(data => {
                                        return tree.layers.map(layer => createHTML(layer, JSON.stringify(data), overrides, '', rootProps));
                                    }),
                                    html``
                                )
                                : ''
                            }
                        </div>
                    `; */
                    return html`
                        ${until(
                        getAccount(rerender, renderState).then(data => {
                            if (props?.response && props.response?.if) {
                                conditionIf = memoizedEvalCode(props.response.if, data, slug, rootProps);
                            }
                            if (conditionIf) {
                                return html`
                                        <div class="frame-${className}${positionSticky ? ' sticky' : ''}" 
                                            id="${attr(props && props?.id ? props.id : '')}" 
                                            style="${attr(props.inlineStyle || !conditionIf && elseStyle ? `${props.inlineStyle || ''} ${!conditionIf && elseStyle ? elseStyle : ''}` : '')}">
                                            ${Array.isArray(tree.layers) ?
                                        tree.layers.map(layer => createHTML(layer, JSON.stringify(data), overrides, '', rootProps))
                                        : ''
                                    }
                                        </div>
                                    `;
                            } else {
                                return html``;
                            }
                        }),
                        html``
                    )}
                    `;
                } else {
                    return html`
                        <div class="frame-${className}${positionSticky ? ' sticky' : ''}" 
                            id="${attr(props && props?.id ? props.id : '')}"
                            href="${attr(link ? link : '')}"
                            transition="${attr(transition ? transition : '')}"
                            scrollto="${attr(scroll_to ? scroll_to : '')}" 
                            style="${attr(props.inlineStyle || !conditionIf && elseStyle || override?.hidden && override.hidden ? `${props.inlineStyle || ''} ${!conditionIf && elseStyle ? elseStyle : ''} ${override?.hidden && override.hidden ? 'display: none;' : ''}` : '')}"
                            clickevent="${attr(onclick ? onclick : '')}"
                            setprops="${attr(setProps ? `${btoa(encodeURIComponent(JSON.stringify(setProps)))}` : '')}"
                        >
                            ${mapping
                            ? mapping.map(item => tree.layers.map(layer => createHTML(layer, JSON.stringify(parseIfString(item)), overrides, '', rootProps, rerender, renderState)))
                            : Array.isArray(tree.layers) ? tree.layers.map(layer => createHTML(layer, JSON.stringify(data), overrides, '', rootProps, rerender, renderState)) : ''
                        }
                        </div>
                    `;

                    /* return html`
                        ${
                            (props?.map && mapping && mapping.length > 0) || props.empty || !props?.map ? 
                            html`
                                <div class="frame-${className}${positionSticky ? ' sticky' : ''}" 
                                    id="${attr(props && props?.id ? props.id : '')}"
                                    href="${attr(link ? link : '')}"
                                    transition="${attr(transition ? transition : '')}"
                                    scrollto="${attr(scroll_to ? scroll_to : '')}" 
                                    style="${attr(props.inlineStyle || !conditionIf && elseStyle ? `${props.inlineStyle || ''} ${!conditionIf && elseStyle ? elseStyle : ''}` : '')}"
                                    clickevent="${attr(onclick ? onclick : '')}"
                                    setprops="${attr(setProps ? `${btoa(encodeURIComponent(JSON.stringify(setProps)))}` : '')}"
                                >
                                    ${mapping 
                                        ? mapping.map(item => tree.layers.map(layer => createHTML(layer, JSON.stringify(parseIfString(item)), overrides, '', rootProps, rerender, renderState)))
                                        : Array.isArray(tree.layers) ? tree.layers.map(layer => createHTML(layer, '', overrides, 'empty_content', rootProps)) : ''
                                    }
                                </div>
                            ` :
                            ''
                        }
                    `; */

                }
                break;
            case 'TEXT':
                if (tree.name == 'js' || (props?.name && props.name == 'js')) {
                    html`${memoizedEvalCode(tree.text, data, slug, rootProps)}`;
                } else if (tree.name === 'input' || (props?.name && props.name == 'input')) {
                    return html`<input 
                        id="${attr(rootProps?.id ? memoizedEvalCode(rootProps.id, data, slug, rootProps) : '')}"
                        type="${attr(rootProps?.type ? rootProps.type : '')}"
                        name="${attr(rootProps?.name ? memoizedEvalCode(rootProps.name, data, slug, rootProps) : '')}"
                        value="${attr(rootProps?.value ? rootProps.value : '')}"
                        placeholder="${attr(rootProps?.placeholder ? rootProps.placeholder : '')}"
                        class="${className}${positionSticky ? ' sticky' : ''}" 
                        href="${attr(link ? link : '')}"
                        clickevent="${attr(onclick ? onclick : '')}"
                        setprops="${attr(setProps ? `${btoa(encodeURIComponent(JSON.stringify(setProps)))}` : '')}"
                        required="${attr(rootProps?.required && rootProps.required === true ? rootProps.required : '')}"
                    />`;
                } else if (tree.name === 'input-placeholder' || (props?.name && props.name == 'input-placeholder')) {
                    return null;
                } else if (tree.name === 'input-image') {
                    return html`<input 
                        id="${attr(rootProps?.id ? memoizedEvalCode(rootProps.id, data, slug, rootProps) : '')}"
                        type="file"
                        accept="image/x-png,image/gif,image/jpeg,image/webp"
                        name="${attr(rootProps?.name ? memoizedEvalCode(rootProps.name, data, slug, rootProps) : '')}"
                        class="${className}${positionSticky ? ' sticky' : ''}" 
                        href="${attr(link ? link : '')}"
                        clickevent="${attr(onclick ? onclick : '')}"
                        setprops="${attr(setProps ? `${btoa(encodeURIComponent(JSON.stringify(setProps)))}` : '')}"
                        required="${attr(rootProps?.required && rootProps.required === true ? rootProps.required : '')}"
                        hidden
                    />`;
                } else if (tree.name === 'textarea') {
                    return html`<textarea 
                        id="${attr(rootProps?.id ? memoizedEvalCode(rootProps.id, data, slug, rootProps) : '')}"
                        name="${attr(rootProps?.name ? memoizedEvalCode(rootProps.name, data, slug, rootProps) : '')}"
                        placeholder="${attr(rootProps?.placeholder ? rootProps.placeholder : '')}"
                        class="${className}${positionSticky ? ' sticky' : ''}" 
                        href="${attr(link ? link : '')}"
                        clickevent="${attr(onclick ? onclick : '')}"
                        setprops="${attr(setProps ? `${btoa(encodeURIComponent(JSON.stringify(setProps)))}` : '')}"
                        required="${attr(rootProps?.required && rootProps.required === true ? rootProps.required : '')}"
                    >${rootProps?.value ? rootProps.value : ''}</textarea>`;
                } else if (tree.name === 'textarea-placeholder') {
                    return null;
                } else {
                    function checkOverride() {
                        if (override?.text && override.text !== undefined) {
                            return memoizedEvalCode(override.text, data, slug, rootProps) || override.text;
                        } else {
                            return memoizedEvalCode(tree.text, data, slug, rootProps) || tree.text;
                        }
                    }
                    return html`<span 
                        class="text-${className}${positionSticky ? ' sticky' : ''}" 
                        href="${attr(link ? link : '')}" 
                        transition="${attr(transition ? transition : '')}" 
                        scrollto="${attr(scroll_to ? scroll_to : '')}" 
                        style="${attr(props.inlineStyle || !conditionIf && elseStyle || override?.hidden && override.hidden ? `${props.inlineStyle || ''} ${!conditionIf && elseStyle ? elseStyle : ''} ${override?.hidden && override.hidden ? 'display: none;' : ''}` : '')}"
                        clickevent="${attr(onclick ? onclick : '')}"
                        setprops="${attr(setProps ? `${btoa(encodeURIComponent(JSON.stringify(setProps)))}` : '')}"
                    >${checkOverride()}</span>`;
                }
                break;
            case 'RECT':
                if (props && props.type === 'img') {
                    const preload = memoizedEvalCode(props.preload, data, slug, rootProps);
                    const image = memoizedEvalCode(props.image, data, slug, rootProps);
                    return html`<img 
                        class="img-${className}${positionSticky ? ' sticky' : ''}" 
                        style="${attr(preload ? `background-image: url('${preload}');` : '')}" 
                        src="${image ? `${config.host}/load.php${props?.size ? `?size=${props.size * pixelRatio}&` : '?'}file=${image}` : ''}"
                        alt="${attr(data?.title ? `${data.title}` : '')}"
                        loading="auto"
                        onload="this.removeAttribute('style');"
                        onerror="this.removeAttribute('style');"
                        onreadystatechange="if(this.readyState === 'complete') this.removeAttribute('style');"
                    />`;
                } else if (props && props.type === 'qr-scanner') {
                    return html`<video 
                        id="${attr(props && props?.id ? props.id : '')}"
                        class="frame-${className}${positionSticky ? ' sticky' : ''}" 
                    ></video>`;
                } else {
                    return null;
                }
                break;
            default: return null;
        }
    } else {
        return null;
    }
    return null;
}
/* export function querySelectorAllDeep(querySelector) {
    const elements = [...document.querySelectorAll(querySelector)];
    const shadowRoots = [...document.querySelectorAll('*')].filter(el => el.shadowRoot);
    shadowRoots.forEach(root => {
        elements.push(...root.shadowRoot.querySelectorAll(querySelector));
    });
    if (elements.length > 0) {
        return elements;
    } else {
        return null;
    }
} */
// Функция для поиска всех элементов, в том числе внутри shadow DOM
/* export function querySelectorAllDeep(selector, root = document.body) {
    const elements = [];

    function traverse(node) {
        // Проверяем дочерние узлы в обычном DOM
        if (node.querySelectorAll) {
            elements.push(...node.querySelectorAll(selector));
        }

        // Проверяем дочерние узлы в shadow DOM
        node.childNodes.forEach(child => {
            if (child.shadowRoot) {
                traverse(child.shadowRoot);
            } else {
                traverse(child);
            }
        });
    }

    // Если у root есть shadow root, начнем с него
    if (root.shadowRoot) {
        traverse(root.shadowRoot);
    } else {
        traverse(root);
    }

    return elements;
} */
// Функция для поиска всех элементов, в том числе внутри shadow DOM без дублирования
export function querySelectorAllDeep(selector, root = document.body) {
    const elements = new Set();

    function traverse(node) {
        // Проверяем и обходим элементы в shadow DOM
        if (node.shadowRoot) {
            traverse(node.shadowRoot);
        }

        // Проверяем дочерние узлы внутри обычного DOM
        if (node.querySelectorAll) {
            const foundElements = node.querySelectorAll(selector);
            foundElements.forEach(el => elements.add(el));
        }

        // Рекурсивно обходим дочерние узлы
        node.childNodes.forEach(child => traverse(child));
    }

    traverse(root);

    return Array.from(elements);
}